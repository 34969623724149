.App {
  text-align: center;
  padding: 20px;
  font-family: 'Noto Serif SC', serif;
}

@font-face {
  font-family: 'Noto Serif SC';
  src: url('./fonts/noto-serif-sc-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Noto Serif SC', serif;
}

.App-title {
  font-family: 'Noto Serif SC', serif;
}

.App-header {
  background-color: #f0f0f0;
  padding: 20px;
  margin: auto;
  max-width: 600px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 599px) {
  .App-header {
    max-width: 100%;
  }
}

.App-avatar {
  border-radius: 50%; /* 使图片保持圆形 */
  position: absolute; /* 使图片可以被居中 */
  width: 100px;
  height: 100px;
}

@keyframes reverse-spin {
  100% {
    transform: rotate(360deg);
  }
}

.App-header h1 {
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .App-header h1 {
    font-family: 'Noto Serif SC', serif;
  }
}

.custom-style {
  font-size: 15px;
  color: #333;
  font-family: 'Noto Serif SC', serif;
  display: block;
  margin: 5px 0;
}

@media (prefers-color-scheme: dark) {
  .custom-style {
    color: #ccc;
  }
}

@media (max-width: 600px) {
  .custom-style {
    font-size: 0.9em;
    line-height: 1.2em; /* 增加行高以避免换行 */
  }
}

.App-links {
  display: flex;
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.icon {
  width: 18px;
  height: 18px;
  fill: currentColor;
  vertical-align: middle;
  display: inline-block; /* 确保图标可以单独调整位置 */
  transform: translateY(-1px); /* 根据需要调整 */
}

.App-links a {
  color: #00ACC1;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  .App-links a {
    color: #18FFFF;
  }
}

.App-header .App-title {
  color: #333;
}

@media (prefers-color-scheme: dark) {
  .App-header {
    background-color: #141414; /* 修改为 #141414 */
    color: #ccc;
  }
  .App-header .App-title {
    color: #ccc;
  }
}

.app-body {
  background-color: #f0f0f0;
}

@media (prefers-color-scheme: dark) {
  .app-body {
    background-color: #141414; /* 修改为 #141414 */
  }
}

@keyframes float {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

.note-icon.floating {
  animation: float 2s ease-in-out infinite;
}


:root {
  --w: #f0f0f0;
  --b: #1d1d1d;
  --s: 1s;
  --d: calc(var(--s) / 6);
}

* {
  box-sizing: border-box;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
}


.content {
  width: 50vmin;
  height: 50vmin;
  background: #f000;
  transform: scale(0.6); /* 缩小整个组件的大小 */
  margin-bottom: -100px; /* 调整这个值来改变距离 */
  margin-top: -130px; /* 添加这个属性来改变上面的距离 */
}
@media (max-width: 600px) {
  .content {
    width: 50vmin;
    height: 50vmin;
    transform: scale(0.8);
    margin-bottom: -50px;
    margin-top: -100px;
  }
}
.dots {
  background: #0ff0;
  width: 100%;
  height: 100%;
  position: relative;
}

.ring {
  border: 0.5vmin solid var(--w);
  width: 64%;
  height: 64%;
  border-radius: 100%;
  z-index: 0;
  box-shadow: 0 0 0 1vmin var(--b), 0 0 0 1vmin var(--b) inset;
  background-size: 100%; /* 缩小背景图片的大小 */
  background-position: center; /* 将背景图片居中 */
}

.dot {
  width: 50%;
  position: absolute;
  height: 8vmin;
  background: #f000;
  left: 0;
  transform-origin: 100% 50%;
  z-index: -1;
  animation: over-ring calc(var(--s) * 2) linear 0s infinite;
}

.dot span {
  width: 5.5vmin;
  height: 5.5vmin;
  left: 0;
  border: 1vmin solid var(--b);
  position: absolute;
  background: var(--w);
  border-radius: 100%;
  animation: ball var(--s) ease-in-out 0s infinite alternate;
}

@media (prefers-color-scheme: light) {
  :root {
    --w: #1d1d1d; /* 在浅色模式下，将白色变量设置为深色 */
    --b: #f0f0f0; /* 在浅色模式下，将黑色变量设置为浅色 */
  }

  .ring {
    border: 0.5vmin solid var(--w);
    width: 64%;
    height: 64%;
    border-radius: 100%;
    z-index: 0;
    box-shadow: 0 0 0 1vmin var(--b), 0 0 0 1vmin var(--b) inset;
    background-size: 100%; /* 缩小背景图片的大小 */
    background-position: center; /* 将背景图片居中 */
  }

  .dot span {
    border: 1vmin solid var(--b);
    background: var(--w);
  }
}

.dot:nth-child(1) {
  transform: rotate(-30deg);
  animation-delay: calc(var(--d) * 0);
}
.dot:nth-child(1) span {
  animation-delay: calc(var(--d) * 0);
}
.dot:nth-child(2) {
  transform: rotate(-60deg);
  animation-delay: calc(var(--d) * -1);
}
.dot:nth-child(2) span {
  animation-delay: calc(var(--d) * -1);
}
.dot:nth-child(3) {
  transform: rotate(-90deg);
  animation-delay: calc(var(--d) * -2);
}
.dot:nth-child(3) span {
  animation-delay: calc(var(--d) * -2);
}
.dot:nth-child(4) {
  transform: rotate(-120deg);
  animation-delay: calc(var(--d) * -3);
}
.dot:nth-child(4) span {
  animation-delay: calc(var(--d) * -3);
}
.dot:nth-child(5) {
  transform: rotate(-150deg);
  animation-delay: calc(var(--d) * -4);
}
.dot:nth-child(5) span {
  animation-delay: calc(var(--d) * -4);
}
.dot:nth-child(6) {
  transform: rotate(-180deg);
  animation-delay: calc(var(--d) * -5);
}
.dot:nth-child(6) span {
  animation-delay: calc(var(--d) * -5);
}
.dot:nth-child(7) {
  transform: rotate(-210deg);
  animation-delay: calc(var(--d) * -6);
}
.dot:nth-child(7) span {
  animation-delay: calc(var(--d) * -6);
}
.dot:nth-child(8) {
  transform: rotate(-240deg);
  animation-delay: calc(var(--d) * -7);
}
.dot:nth-child(8) span {
  animation-delay: calc(var(--d) * -7);
}
.dot:nth-child(9) {
  transform: rotate(-270deg);
  animation-delay: calc(var(--d) * -8);
}
.dot:nth-child(9) span {
  animation-delay: calc(var(--d) * -8);
}
.dot:nth-child(10) {
  transform: rotate(-300deg);
  animation-delay: calc(var(--d) * -9);
}
.dot:nth-child(10) span {
  animation-delay: calc(var(--d) * -9);
}
.dot:nth-child(11) {
  transform: rotate(-330deg);
  animation-delay: calc(var(--d) * -10);
}
.dot:nth-child(11) span {
  animation-delay: calc(var(--d) * -10);
}
.dot:nth-child(12) {
  transform: rotate(-360deg);
  animation-delay: calc(var(--d) * -11);
}
.dot:nth-child(12) span {
  animation-delay: calc(var(--d) * -11);
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes ball {
  100% {
    left: 12vmin;
    width: 4vmin;
    height: 4vmin;
  }
}
@keyframes over-ring {
  0%, 50% {
    z-index: -1;
  }
  51%, 100% {
    z-index: 1;
  }
}